import React from 'react'
import dynamic from 'next/dynamic'
import App, { AppInitialProps } from 'next/app'
import Script from 'next/script'
import NextHead from 'next/head'
import { withRouter, NextRouter } from 'next/router'
import { getCookies, removeCookies, setCookies } from 'cookies-next';
import { ThemeProvider } from 'styled-components'
import { ChakraProvider } from '@chakra-ui/react'
import Bugsnag from 'src/utils/bugsnag'
import { GtmUser } from 'src/components/common/GoogleTagManager'
import { wrapper } from 'src/redux'
import { theme } from 'src/themes'
import { carPlannerTheme } from 'src/themes/carplanner'
import GlobalBaseStyle from 'src/styles/styled-components/GlobalBaseStyle'
import Error from './_error'

import 'src/i18n'

const Layout = dynamic(() => import('src/components/Layout'))
const GtmInit = dynamic(() => import('src/components/common/GoogleTagManager/Initialize'))

const ErrorBoundary = Bugsnag.getPlugin('react')

const pagesWithoutLayout = [
  '/noleggio-a-lungo-termine/conversation/[code]'
]

const pagesWithoutContactSection = [
  '/noleggio-a-lungo-termine/[landing]/[model]/[code]'
]

const pagesWithoutContactAction = [
  '/contatti'
]

const pagesWithoutModalResume = [
  '/caricamento-documenti',
  '/completa-richiesta'
]
class WrappedApp extends App<AppInitialProps & { router: NextRouter }> {
  static getCookies(ctx) {
    const { req, res } = ctx
    return getCookies({ req, res })
  }

  static setCookie(ctx, name, value) {
    const { req, res } = ctx
    setCookies(name, value, { req, res })
  }

  static unsetCookie(ctx, name) {
    const { req, res } = ctx
    removeCookies(name, { req, res })
  }

  public static getInitialProps = wrapper.getInitialAppProps(store => async ({ Component, ctx, router }) => {
    const { searchParams } = new URL(router.asPath, 'http://carplanner.com')
    const userToken = searchParams.get('C') || null; // e.g. 170f064a-9bd8-47f9-8676-ecf04db18334
    const externalReference = searchParams.get('E') || null;

    if (userToken && userToken.length > 10) {
      this.setCookie(ctx, 'userAuthId', userToken);
    }

    if (externalReference && externalReference.length > 1) {
      this.setCookie(ctx, 'externalReference', externalReference);
    }

    return {
      pageProps: {
        ...(Component.getInitialProps ? await Component.getInitialProps({ ...ctx }) : {}),
        pathname: ctx.pathname,
        state: store.getState(),
      },
    }
  })


  public render() {
    const { Component, pageProps } = this.props;
    const { pathname } = pageProps;
    const isFullScreen = pagesWithoutLayout.includes(pathname);
    const hasContactSection = !pagesWithoutContactSection.includes(pathname);
    const hasContactAction = !pagesWithoutContactAction.includes(pathname);
    const hasModalResume = !pagesWithoutModalResume.includes(pathname);

    return (
      // @ts-ignore
      <ErrorBoundary FallbackComponent={Error}>
        <ThemeProvider theme={carPlannerTheme}>
          <ChakraProvider theme={theme}>
            <GtmInit />
            <Script
              id="noFouc"
              strategy="afterInteractive"
              dangerouslySetInnerHTML={{
                __html: `document.body.classList.remove("no-fouc");`,
              }}
            />
            <Layout
              isFullScreen={isFullScreen}
              hasContactSection={hasContactSection}
              hasContactAction={hasContactAction}
              hasModalResume={hasModalResume}
            >
              <GlobalBaseStyle />
              {pageProps?.state?.user?.profile?.user && <GtmUser userId={pageProps.state.user.profile.user.id} />}
              <NextHead>
                <link rel="shortcut icon" href={`/favicon.ico?${(new Date()).getFullYear()}${(new Date()).getMonth()}`} type="image/x-icon" />
                <link rel="apple-touch-icon" href="/favicon/apple-icon-180x180.png" />
              </NextHead>
              <Component {...pageProps} />
            </Layout>
          </ChakraProvider>
        </ThemeProvider >
      </ErrorBoundary>
    )
  }
}

export default wrapper.withRedux(withRouter(WrappedApp))
